import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { getTaiwanTimeString } from './TaiwanTimeStamp';
import useUserStore from '../stores/useUserStore';
import { db } from './Firebase';
import MedicineSearch from './MedicineSearch';
import Uploader from './Uploader';
import moment from 'moment';

// 儲存提示模態框元件
const SavePromptModal = ({ isOpen, onClose, onSave, onDiscard }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-xl">
        <h2 className="text-xl font-bold mb-4">未儲存的變更</h2>
        <p className="mb-4">您有未儲存的病歷資料，要如何處理？</p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onSave}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            儲存
          </button>
          <button
            onClick={onDiscard}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            不儲存
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
          >
            取消
          </button>
        </div>
      </div>
    </div>
  );
};

// 使用 forwardRef 允許父組件使用 ref
const ConsultationForm = forwardRef(({
  selectedPatient,
  selectedDoctor,
  onPatientPreventSwitch,
  currentRecord
}, ref) => {
  const [complains, setComplains] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [medications, setMedications] = useState([]);
  const [patientImages, setPatientImages] = useState([]);
  const { targetClinicId, targetClinicName } = useUserStore();
  const [diagnosisCodeInput, setDiagnosisCodeInput] = useState('');
  const [diagnosisCodes, setDiagnosisCodes] = useState([]);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isSavePromptOpen, setIsSavePromptOpen] = useState(false);
  const [recordId, setRecordId] = useState(null);

  useEffect(() => {
    if (currentRecord) {
      // Populate form with record data
      setComplains(currentRecord.complains || '');
      setDiagnosis(currentRecord.diagnosis || '');
      setMedications(currentRecord.medications || []);
      setPatientImages(currentRecord.imageUrls || []);
      setDiagnosisCodes(currentRecord.diagnosisCode ? currentRecord.diagnosisCode.split(', ') : []);
      setRecordId(currentRecord.id || null);
      setIsFormDirty(false);  // Reset dirty state since we're loading saved data
    }
  }, [currentRecord]);

  // 檢查表單是否有未儲存的變更
  useEffect(() => {
    const isDirty = complains || diagnosis || medications.length > 0 || patientImages.length > 0 || diagnosisCodes.length > 0;
    setIsFormDirty(isDirty);
  }, [complains, diagnosis, medications, patientImages, diagnosisCodes]);

  // 當有新的病人被選擇時重置表單
  useEffect(() => {
    resetForm();
  }, [selectedPatient?.id]);

  // 使用 useImperativeHandle 將方法暴露給父組件
  useImperativeHandle(ref, () => ({
    canSwitchPatient: () => !isFormDirty,

    preventPatientSwitch: (originalPatient) => {
      if (isFormDirty) {
        setIsSavePromptOpen(true);
        onPatientPreventSwitch?.(originalPatient);
        return false;
      }
      return true;
    }
  }));

  // 重置表單方法
  const resetForm = () => {
    setComplains('');
    setDiagnosis('');
    setMedications([]);
    setPatientImages([]);
    setDiagnosisCodes([]);
    setIsFormDirty(false);
  };

  // 新增診斷碼
  const handleAddDiagnosisCode = () => {
    if (diagnosisCodeInput.trim()) {
      setDiagnosisCodes(prev => [...prev, diagnosisCodeInput.trim()]);
      setDiagnosisCodeInput('');
    }
  };

  // 刪除診斷碼
  const handleDeleteDiagnosisCode = (index) => {
    const newCodes = diagnosisCodes.filter((_, i) => i !== index);
    setDiagnosisCodes(newCodes);
  };

  const hasContent = () => {
    return (
      complains.trim() !== '' ||
      diagnosis.trim() !== '' ||
      medications.length > 0 ||
      patientImages.length > 0 ||
      diagnosisCodes.length > 0
    );
  };

  // 儲存病歷資料
  const saveData = async () => {
    if (!selectedPatient || !selectedDoctor) {
      toast.error('缺少病患或醫生資訊');
      return;
    }

    if (!hasContent()) {
      return;
    }

    try {
      const recordData = {
        patientId: selectedPatient.id,
        patientName: selectedPatient.userName,
        patientTwId: selectedPatient.userTWId,
        clinicId: targetClinicId,
        clinicName: targetClinicName,
        doctorId: selectedDoctor.doctorId,
        doctorName: selectedDoctor.name,
        complains,
        diagnosis,
        diagnosisCode: diagnosisCodes.join(', '),
        medications,
        imageUrls: patientImages,
        lastUpdated: getTaiwanTimeString(),
        createdAt: getTaiwanTimeString(),
        status: '草稿'
      };

      const recordId = `${selectedPatient.id}_${getTaiwanTimeString()}`;
      const docRef = doc(db, 'MedicalRecords', recordId);
      await setDoc(docRef, recordData);

      toast.success('病歷已儲存');
      setIsFormDirty(false);
      setIsSavePromptOpen(false);

    } catch (error) {
      console.error('Error saving medical record:', error);
      toast.error('儲存失敗');
    }
  };

  // Complete consultation and update appointment status
  const completeConsultation = async () => {
    if (!selectedPatient || !selectedDoctor) {
      toast.error('缺少病患或醫生資訊');
      return;
    }

    if (!hasContent()) {
      toast.error('病歷內容不能全部為空');
      return;
    }

    try {
      // First save the medical record
      const recordData = {
        patientId: selectedPatient.id,
        patientName: selectedPatient.userName,
        patientTwId: selectedPatient.userTWId,
        clinicId: targetClinicId,
        clinicName: targetClinicName,
        doctorId: selectedDoctor.doctorId,
        doctorName: selectedDoctor.name,
        complains,
        diagnosis,
        diagnosisCode: diagnosisCodes.join(', '),
        medications,
        imageUrls: patientImages,
        lastUpdated: getTaiwanTimeString(),
        createdAt: getTaiwanTimeString(),
        status: '完成'
      };

      const recordId = `${selectedPatient.id}_${getTaiwanTimeString()}`;
      const docRef = doc(db, 'MedicalRecords', recordId);
      await setDoc(docRef, recordData);

      // Then update the appointment status
      const appointmentRef = doc(db, 'Appointment', selectedPatient.id);
      await updateDoc(appointmentRef, {
        status: '已看診',
        medicalRecordId: recordId,
        lastUpdated: getTaiwanTimeString()
      });

      toast.success('完成看診並儲存病歷');
      resetForm();
      window.location.reload(); // Only refresh on completion, not on save

    } catch (error) {
      console.error('Error completing consultation:', error);
      toast.error('完成看診失敗');
    }
  };

  // 上傳圖片
  const handleImageUpload = async (imageUrl) => {
    setPatientImages(prev => [...prev, imageUrl]);
  };

  // 刪除圖片
  const handleImageDelete = async (urlToDelete) => {
    setPatientImages(prev => prev.filter(url => url !== urlToDelete));
  };

  // 如果沒有選擇病患，顯示提示
  if (!selectedPatient) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-gray-500 text-lg">
          請從左側選擇病患以開始看診
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* 儲存提示模態框 */}
      <SavePromptModal
        isOpen={isSavePromptOpen}
        onClose={() => setIsSavePromptOpen(false)}
        onSave={saveData}
        onDiscard={() => {
          resetForm();
          setIsSavePromptOpen(false);
        }}
      />

      {/* 患者資訊區塊 */}
      <div className="grid grid-cols-4 gap-4 bg-gray-50 p-4 rounded-lg">
        <div>
          <p className="text-sm text-gray-500">姓名</p>
          <p className="font-medium">{selectedPatient.userName}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">身分證</p>
          <p className="font-medium">{selectedPatient.userTWId}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">生日</p>
          <p className="font-medium">{selectedPatient.birthday}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">年齡</p>
          <p className="font-medium">
            {moment().diff(moment(selectedPatient.birthday), 'years')}歲
          </p>
        </div>
      </div>

      {/* 主要病歷表單 */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          {/* 主訴區塊 */}
          <div>
            <label className="block text-l font-medium text-gray-700 mb-2">
              主訴
            </label>
            <textarea
              value={complains}
              onChange={(e) => setComplains(e.target.value)}
              rows={4}
              className="w-full p-2 border border-gray-300 rounded-lg"
              placeholder="病患主訴..."
            />
          </div>

          {/* 診斷碼區塊 */}
          <div>
            <label className="block text-l font-medium text-gray-700 mb-2">
              診斷碼
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                value={diagnosisCodeInput}
                onChange={(e) => setDiagnosisCodeInput(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleAddDiagnosisCode()}
                className="w-full p-2 border border-gray-300 rounded-lg"
                placeholder="輸入診斷碼後按 Enter 或 +"
              />
              <button
                onClick={handleAddDiagnosisCode}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                +
              </button>
            </div>
            <div className="mt-2">
              {diagnosisCodes.map((code, index) => (
                <div key={index} className="flex items-center justify-between bg-gray-100 p-2 rounded-lg mt-1">
                  <span>{code}</span>
                  <button
                    onClick={() => handleDeleteDiagnosisCode(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    刪除
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="space-y-4">
          {/* 診斷區塊 */}
          <div>
            <label className="block text-l font-medium text-gray-700 mb-2">
              診斷
            </label>
            <textarea
              value={diagnosis}
              onChange={(e) => setDiagnosis(e.target.value)}
              rows={4}
              className="w-full p-2 border border-gray-300 rounded-lg"
              placeholder="診斷結果..."
            />
          </div>
        </div>
      </div>

      {/* 藥令區塊 */}
      <div>
        <label className="block text-l font-medium text-gray-700 mb-2">
          藥令
        </label>
        <MedicineSearch
          medications={medications}
          setMedications={setMedications}
        />
      </div>

      {/* 圖片上傳區塊 */}
      <div>
        <label className="block text-l font-medium text-gray-700 mb-2">
          上傳圖片
        </label>
        <Uploader
          onUpload={handleImageUpload}
          onDelete={handleImageDelete}
          images={patientImages}
        />
      </div>

      {/* 儲存按鈕和完成看診按鈕 */}
      <div className="flex justify-end space-x-4">
        <button
          onClick={saveData}
          className="px-4 py-2 bg-green-400 text-white rounded hover:bg-blue-600"
        >
          儲存病歷
        </button>
        <button
          onClick={completeConsultation}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-green-600"
        >
          儲存並完成接診
        </button>
      </div>
    </div>
  );
});

export default ConsultationForm;