import React, { useEffect } from 'react';
import toast from 'react-hot-toast';

const DoctorSelector = ({ doctors, selectedDoctor, onDoctorSelect }) => {
  const handleDoctorChange = (e) => {
    const doctor = doctors.find(d => d.doctorId === e.target.value);
    if (doctor) {
      // Update URL with the selected doctor ID
      const newUrl = `${window.location.pathname}?doctorId=${doctor.doctorId}`;
      window.history.pushState({ doctorId: doctor.doctorId }, '', newUrl);
      onDoctorSelect(doctor);
    }
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-2">
        選擇醫師
      </label>
      <select
        value={selectedDoctor?.doctorId || ''}
        onChange={handleDoctorChange}
        className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
      >
        {doctors.map((doctor) => (
          <option key={doctor.doctorId} value={doctor.doctorId}>
            {doctor.doctorName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DoctorSelector;