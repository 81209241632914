import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { collection, query, where, getDocs, orderBy, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { db } from './Firebase';
import useUserStore from '../stores/useUserStore';
import Conversation from '../screens/Chats/Coversation';
import { FiEye, FiCopy, FiTrash } from 'react-icons/fi';

const HistoryAndChat = ({ selectedPatient, onViewRecord }) => {
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const { targetClinicId } = useUserStore();

  // Set up real-time listener for medical records
  useEffect(() => {
    if (!selectedPatient || !targetClinicId) return;

    setLoading(true);

    const q = query(
      collection(db, 'MedicalRecords'),
      where('patientId', '==', selectedPatient.id),
      where('clinicId', '==', targetClinicId),
      orderBy('lastUpdated', 'desc')
    );

    // Create real-time listener
    const unsubscribe = onSnapshot(q,
      (snapshot) => {
        const records = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMedicalRecords(records);
        setLoading(false);
      },
      (error) => {
        console.error('Error in medical records listener:', error);
        toast.error('監聽病歷記錄失敗');
        setLoading(false);
      }
    );

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, [selectedPatient, targetClinicId]);

  // Handle record deletion
  const handleDeleteRecord = async (recordId) => {
    const confirmDelete = window.confirm('確定要刪除此病歷記錄嗎？此操作無法復原。');
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'MedicalRecords', recordId));
      toast.success('病歷記錄已刪除');
    } catch (error) {
      console.error('Error deleting record:', error);
      toast.error('刪除病歷記錄失敗');
    }
  };

  // Get status badge color
  const getStatusBadgeColor = (status) => {
    switch (status) {
      case '完成':
        return 'bg-green-100 text-green-800';
      case '草稿':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const MedicalHistoryTab = () => (
    <div className="space-y-4 max-h-[calc(100vh-300px)] overflow-y-auto">
      {loading ? (
        <div className="flex justify-center py-4">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main"></div>
        </div>
      ) : medicalRecords.length === 0 ? (
        <p className="text-center text-gray-500 py-4">無歷史病歷</p>
      ) : (
        medicalRecords.map((record) => (
          <div key={record.id} className="border border-gray-200 rounded-lg p-4 relative">
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center gap-2">
                <p className="text-sm text-gray-500">
                  {moment(record.lastUpdated).format('YYYY-MM-DD HH:mm')}
                </p>
                <span className={`text-xs px-2 py-1 rounded-full ${getStatusBadgeColor(record.status)}`}>
                  {record.status || '草稿'}
                </span>
              </div>
            </div>
            <div className="space-y-2">
              <div>
                <p className="text-l font-medium text-gray-700">{selectedPatient.userName}</p>
                <p className="text-sm text-gray-500">主訴</p>
                <p className="text-sm">{record.complains}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">診斷</p>
                <p className="text-sm">{record.diagnosis}</p>
                {record.diagnosisCode && (
                  <p className="text-sm text-gray-500">
                    診斷碼: {record.diagnosisCode}
                  </p>
                )}
              </div>
              {record.medications?.length > 0 && (
                <div>
                  <p className="text-sm text-gray-500">用藥</p>
                  <div className="space-y-1">
                    {record.medications.map((med, index) => (
                      <p key={index} className="text-sm">
                        {med.name} - {med.dosage} {med.frequency} {med.duration}
                      </p>
                    ))}
                  </div>
                </div>
              )}
              {record.imageUrls?.length > 0 && (
                <div className="grid grid-cols-2 gap-2">
                  {record.imageUrls.map((url, index) => (
                    <img
                      key={index}
                      src={url}
                      alt={`Medical record ${index + 1}`}
                      className="w-full h-24 object-cover rounded cursor-pointer"
                      onClick={() => window.open(url, '_blank')}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className="absolute bottom-4 right-4 flex items-center gap-2">
              <button
                onClick={() => onViewRecord(record)}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                title="複製病歷"
              >
                <FiCopy className="h-4 w-4 text-green-600" />
              </button>
              <button
                onClick={() => handleDeleteRecord(record.id)}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                title="刪除病歷"
              >
                <FiTrash className="h-4 w-4 text-red-600" />
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );

  const ChatTab = () => (
    <div className="h-[calc(100vh-300px)]">
      {selectedPatient ? (
        <Conversation
          selectedPatient={{
            id: selectedPatient.id,
            patientName: selectedPatient.userName,
            patientUserId: selectedPatient.userId
          }}
        />
      ) : (
        <div className="flex items-center justify-center h-full">
          <p className="text-gray-500">請選擇病患</p>
        </div>
      )}
    </div>
  );

  return (
    <Tab.Group>
      <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-4">
        <Tab
          className={({ selected }) =>
            `w-full rounded-lg py-2 text-sm font-medium leading-5
            ${selected
              ? 'bg-white text-blue-700 shadow'
              : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'
            }`
          }
        >
          病歷記錄
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <MedicalHistoryTab />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default HistoryAndChat;