import { collection, doc, getDoc, getDocs, setDoc, query, where, orderBy, limit } from 'firebase/firestore';
import { db } from '../Firebase';

class AnalyticsService {
  // Collection references
  static COLLECTIONS = {
    metrics: 'Metrics',
    pageViews: 'PageViews',
    buttonClicks: 'ButtonClicks',
    errors: 'ErrorLogs'
  };

  // Track page views with session handling
  static async trackPageView(pageName) {
    try {
      const sessionId = this.getSessionId();
      const timestamp = new Date();
      const dateKey = this.getDateKey(timestamp);

      // Check if this page was already viewed in this session
      const existingView = sessionStorage.getItem(`pv_${pageName}`);
      if (existingView) {
        return; // Skip if already viewed in this session
      }

      // Mark page as viewed in this session
      sessionStorage.setItem(`pv_${pageName}`, dateKey);

      // Store individual page view event
      const eventRef = doc(collection(db, this.COLLECTIONS.pageViews));
      await setDoc(eventRef, {
        page: pageName,
        timestamp,
        sessionId,
        dateKey,
        userAgent: navigator.userAgent,
        referrer: document.referrer || 'direct'
      });

      // Update daily aggregated metrics
      const dailyMetricsRef = doc(db, this.COLLECTIONS.metrics, `pageview_${dateKey}_${pageName}`);
      const docSnap = await getDoc(dailyMetricsRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        await setDoc(dailyMetricsRef, {
          ...data,
          count: (data.count || 0) + 1,
          lastVisit: timestamp
        }, { merge: true });
      } else {
        await setDoc(dailyMetricsRef, {
          page: pageName,
          dateKey,
          count: 1,
          firstVisit: timestamp,
          lastVisit: timestamp
        });
      }

    } catch (error) {
      console.error('Error tracking page view:', error);
      this.trackError(error, 'AnalyticsService', { method: 'trackPageView' });
    }
  }

  // Track button clicks with detailed data
  static async trackButtonClick(buttonId, componentName) {
    try {
      const timestamp = new Date();
      const dateKey = this.getDateKey(timestamp);
      const sessionId = this.getSessionId();

      // Store individual click event
      const eventRef = doc(collection(db, this.COLLECTIONS.buttonClicks));
      await setDoc(eventRef, {
        buttonId,
        componentName,
        timestamp,
        sessionId,
        dateKey,
        path: window.location.pathname
      });

      // Update daily aggregated metrics
      const dailyMetricsRef = doc(db, this.COLLECTIONS.metrics, `click_${dateKey}_${buttonId}`);
      const docSnap = await getDoc(dailyMetricsRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        await setDoc(dailyMetricsRef, {
          ...data,
          count: (data.count || 0) + 1,
          lastClick: timestamp
        }, { merge: true });
      } else {
        await setDoc(dailyMetricsRef, {
          buttonId,
          componentName,
          dateKey,
          count: 1,
          firstClick: timestamp,
          lastClick: timestamp
        });
      }

    } catch (error) {
      console.error('Error tracking button click:', error);
      this.trackError(error, 'AnalyticsService', { method: 'trackButtonClick' });
    }
  }

  // Track errors with detailed context
  static async trackError(error, componentName, context = {}) {
    try {
      const timestamp = new Date();
      const dateKey = this.getDateKey(timestamp);
      const sessionId = this.getSessionId();

      // Store individual error event
      const eventRef = doc(collection(db, this.COLLECTIONS.errors));
      await setDoc(eventRef, {
        error: error.message,
        stack: error.stack,
        componentName,
        context,
        timestamp,
        sessionId,
        dateKey,
        path: window.location.pathname,
        userAgent: navigator.userAgent
      });

      // Update daily aggregated metrics
      const dailyMetricsRef = doc(db, this.COLLECTIONS.metrics, `error_${dateKey}_${componentName}`);
      const docSnap = await getDoc(dailyMetricsRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        await setDoc(dailyMetricsRef, {
          ...data,
          count: (data.count || 0) + 1,
          lastError: timestamp
        }, { merge: true });
      } else {
        await setDoc(dailyMetricsRef, {
          componentName,
          dateKey,
          count: 1,
          firstError: timestamp,
          lastError: timestamp
        });
      }

    } catch (err) {
      console.error('Error tracking error:', err);
    }
  }

  // Get all metrics for dashboard
  static async getDashboardMetrics(days = 30) {
    try {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - days);

      const metrics = {
        pageViews: await this.getTimeSeriesMetrics('pageview', startDate, endDate),
        buttonClicks: await this.getTimeSeriesMetrics('click', startDate, endDate),
        errors: await this.getTimeSeriesMetrics('error', startDate, endDate),
        recentActivity: await this.getRecentActivity()
      };

      return metrics;
    } catch (error) {
      console.error('Error fetching metrics:', error);
      throw error;
    }
  }

  // Get time series metrics
  static async getTimeSeriesMetrics(type, startDate, endDate) {
    const startKey = this.getDateKey(startDate);
    const endKey = this.getDateKey(endDate);

    const q = query(
      collection(db, this.COLLECTIONS.metrics),
      where('dateKey', '>=', startKey),
      where('dateKey', '<=', endKey),
      orderBy('dateKey')
    );

    const snapshot = await getDocs(q);
    const timeSeriesData = {};

    snapshot.forEach(doc => {
      const data = doc.data();
      if (doc.id.startsWith(type)) {
        const date = data.dateKey;
        if (!timeSeriesData[date]) {
          timeSeriesData[date] = {
            date,
            total: 0,
            byItem: {}
          };
        }

        const itemId = doc.id.split('_')[2]; // Extract page/button/component name
        timeSeriesData[date].total += data.count;
        timeSeriesData[date].byItem[itemId] = data.count;
      }
    });

    return Object.values(timeSeriesData);
  }

  // Get recent activity
  // Get recent activity
  static async getRecentActivity(limitCount = 1000) {
    const recentActivity = [];

    try {
      // Get recent page views
      const pageViewsQuery = query(
        collection(db, this.COLLECTIONS.pageViews),
        orderBy('timestamp', 'desc'),
        limit(limitCount)
      );
      const pageViewsSnapshot = await getDocs(pageViewsQuery);
      pageViewsSnapshot.forEach(doc => {
        const data = doc.data();
        recentActivity.push({
          type: 'pageView',
          ...data,
          // Convert Firestore timestamp to JS Date for consistent sorting
          timestamp: data.timestamp?.toDate?.() || new Date(data.timestamp)
        });
      });

      // Get recent clicks
      const clicksQuery = query(
        collection(db, this.COLLECTIONS.buttonClicks),
        orderBy('timestamp', 'desc'),
        limit(limitCount)
      );
      const clicksSnapshot = await getDocs(clicksQuery);
      clicksSnapshot.forEach(doc => {
        const data = doc.data();
        recentActivity.push({
          type: 'buttonClick',
          ...data,
          // Convert Firestore timestamp to JS Date for consistent sorting
          timestamp: data.timestamp?.toDate?.() || new Date(data.timestamp)
        });
      });

      // Sort all activity by timestamp, ensuring they're proper Date objects
      return recentActivity.sort((a, b) => {
        const timeA = a.timestamp instanceof Date ? a.timestamp : new Date(a.timestamp);
        const timeB = b.timestamp instanceof Date ? b.timestamp : new Date(b.timestamp);
        return timeB - timeA;
      });

    } catch (error) {
      console.error('Error getting recent activity:', error);
      this.trackError(error, 'AnalyticsService', { method: 'getRecentActivity' });
      return []; // Return empty array on error
    }
  }

  // Helper methods
  static getSessionId() {
    let sessionId = sessionStorage.getItem('analytics_session_id');
    if (!sessionId) {
      sessionId = `${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;
      sessionStorage.setItem('analytics_session_id', sessionId);
    }
    return sessionId;
  }

  static getDateKey(date) {
    return date.toISOString().split('T')[0];
  }

  static formatDate(date) {
    return date.toISOString();
  }
}

export default AnalyticsService;