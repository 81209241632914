import React, { useState, useEffect } from 'react';

const MedicineSearch = ({ medications, setMedications, onUpdate }) => {
  const [manualInput, setManualInput] = useState('');

  // 預設的劑量、頻率和持續時間選項
  const dosageOptions = ['1顆', '2顆', '3顆', '半顆', '1包', '2包'];
  const frequencyOptions = ['每天1次', '每天2次', '每天3次', '每4小時1次', '每6小時1次'];
  const durationOptions = ['1天', '3天', '5天', '7天', '10天', '14天'];

  const handleManualInputChange = (e) => {
    setManualInput(e.target.value);
  };

  const handleAddManualMedication = () => {
    if (manualInput.trim() === '') {
      return; // 如果輸入為空，則不執行任何操作
    }

    const newMedication = {
      id: Date.now(), // 使用時間戳作為唯一 ID
      name: manualInput,
      dosage: dosageOptions[0], // 預設選擇第一個劑量選項
      frequency: frequencyOptions[0], // 預設選擇第一個頻率選項
      duration: durationOptions[0], // 預設選擇第一個持續時間選項
      total: calculateTotal(dosageOptions[0], frequencyOptions[0], durationOptions[0]) // 計算總量
    };

    setMedications(prev => [...prev, newMedication]);
    setManualInput(''); // 清空輸入欄位

    // 檢查 onUpdate 是否存在且是函數
    if (typeof onUpdate === 'function') {
      onUpdate();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddManualMedication();
    }
  };

  // 計算總量的函數
  const calculateTotal = (dosage, frequency, duration) => {
    const dosageValue = parseFloat(dosage); // 提取劑量數字
    const frequencyValue = parseInt(frequency.match(/\d+/)[0]); // 提取頻率數字
    const durationValue = parseInt(duration.match(/\d+/)[0]); // 提取持續時間數字
    return dosageValue * frequencyValue * durationValue;
  };

  const updateMedication = (index, field, value) => {
    setMedications(prev => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [field]: value };

      // 如果更新的是劑量、頻率或持續時間，則重新計算總量
      if (field === 'dosage' || field === 'frequency' || field === 'duration') {
        updated[index].total = calculateTotal(
          updated[index].dosage,
          updated[index].frequency,
          updated[index].duration
        );
      }

      return updated;
    });

    // 檢查 onUpdate 是否存在且是函數
    if (typeof onUpdate === 'function') {
      onUpdate();
    }
  };

  const removeMedication = (index) => {
    setMedications(prev => prev.filter((_, i) => i !== index));

    // 檢查 onUpdate 是否存在且是函數
    if (typeof onUpdate === 'function') {
      onUpdate();
    }
  };

  return (
    <div className="space-y-4">
      {/* 手動輸入藥品名稱的欄位 */}
      <div className="flex items-center gap-2">
        <input
          type="text"
          value={manualInput}
          onChange={handleManualInputChange}
          onKeyPress={handleKeyPress}
          placeholder="輸入藥品名稱"
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
        <button
          onClick={handleAddManualMedication}
          className="p-2 bg-blue-300 text-white rounded-lg hover:bg-blue-500"
        >
          ➕
        </button>
      </div>

      {/* 顯示已添加的藥品列表 */}
      <div className="space-y-2">
        {medications.map((med, index) => (
          <div key={med.id} className="flex items-center gap-2 p-2 border border-gray-200 rounded-lg">
            <div className="flex-1">
              <p className="font-medium">{med.name}</p>
              <div className="flex gap-2 mt-2">
                {/* 劑量下拉選單 */}
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">劑量</label>
                  <select
                    value={med.dosage}
                    onChange={(e) => updateMedication(index, 'dosage', e.target.value)}
                    className="w-full p-1 border border-gray-300 rounded"
                  >
                    {dosageOptions.map((option, i) => (
                      <option key={i} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>

                {/* 頻率下拉選單 */}
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">頻率</label>
                  <select
                    value={med.frequency}
                    onChange={(e) => updateMedication(index, 'frequency', e.target.value)}
                    className="w-full p-1 border border-gray-300 rounded"
                  >
                    {frequencyOptions.map((option, i) => (
                      <option key={i} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>

                {/* 持續時間下拉選單 */}
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">持續時間</label>
                  <select
                    value={med.duration}
                    onChange={(e) => updateMedication(index, 'duration', e.target.value)}
                    className="w-full p-1 border border-gray-300 rounded"
                  >
                    {durationOptions.map((option, i) => (
                      <option key={i} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>

                {/* 總量顯示 */}
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700">總量</label>
                  <input
                    type="text"
                    value={`${med.total} 單位`}
                    readOnly
                    className="w-full p-1 border border-gray-300 rounded bg-gray-100"
                  />
                </div>
              </div>
            </div>
            <button
              onClick={() => removeMedication(index)}
              className="p-2 text-red-500 hover:text-red-600"
            >
              ✕
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MedicineSearch;