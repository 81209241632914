import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../Layout';
import useUserStore from '../../stores/useUserStore';
import DoctorSelector from '../../components/DoctorSelector';
import PatientList from '../../components/PatientList';
import ConsultationForm from '../../components/ConsultationForm';
import HistoryAndChat from '../../components/HistoryAndChat';
import { doc, getDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { db } from '../../components/Firebase';

const DoctorConsultation = () => {
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [doctors, setDoctors] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const { user, targetClinicId, loading } = useUserStore();
  const { doctorId } = useParams();
  const consultationFormRef = useRef(null);
  const patientListRef = useRef(null);

  useEffect(() => {
    const fetchDoctors = async () => {
      if (!targetClinicId) return;

      try {
        const clinicRef = doc(db, 'OwnedClinic', targetClinicId);
        const clinicDoc = await getDoc(clinicRef);

        if (clinicDoc.exists()) {
          const clinicData = clinicDoc.data();
          const doctorsList = clinicData.doctors || [];

          setDoctors(doctorsList);

          const urlParams = new URLSearchParams(window.location.search);
          const urlDoctorId = urlParams.get('doctorId');

          if (urlDoctorId) {
            const doctor = doctorsList.find(d => d.doctorId === urlDoctorId);
            if (doctor) {
              setSelectedDoctor(doctor);
            }
          } else if (doctorsList.length > 0) {
            setSelectedDoctor(doctorsList[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching doctors:', error);
        toast.error('無法載入診所醫師資料');
      }
    };

    fetchDoctors();
  }, [targetClinicId]);

  const handlePatientSelect = (patient) => {
    if (consultationFormRef.current) {
      if (!consultationFormRef.current.preventPatientSwitch(selectedPatient)) {
        return false;
      }
    }
    setSelectedPatient(patient);
    return true;
  };

  const handlePatientPreventSwitch = (originalPatient) => {
    setSelectedPatient(originalPatient);
    patientListRef.current?.revertSelection(originalPatient?.id);
  };

  const handleViewRecord = (record) => {
    // Load the record into the consultation form
    setCurrentRecord(record);
  };

  const handleCopyRecord = (record) => {
    // Create a copy of the record without the ID and status
    const recordCopy = { ...record };
    delete recordCopy.id;
    delete recordCopy.status;
    delete recordCopy.createdAt;
    delete recordCopy.lastUpdated;

    setCurrentRecord(recordCopy);
    // Clear the form's record ID to create a new record
    consultationFormRef.current?.clearForm();
  };

  if (loading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-main"></div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="flex flex-col">
        <h1 className="text-xl font-semibold mb-6">醫師門診</h1>

        {/* Responsive grid container */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
          {/* Left Section - Doctor Selection & Patient List */}
          {/* On mobile: Full width at top */}
          {/* On desktop: 2 columns on the left */}
          <div className="lg:col-span-2 bg-white rounded-xl border border-gray-200 p-4 order-1 lg:order-1">
            <DoctorSelector
              doctors={doctors}
              selectedDoctor={selectedDoctor}
              onDoctorSelect={setSelectedDoctor}
            />
            <PatientList
              ref={patientListRef}
              selectedDoctor={selectedDoctor}
              targetClinicId={targetClinicId}
              onPatientSelect={handlePatientSelect}
              searchQuery={searchQuery}
              onSearchChange={setSearchQuery}
              selectedPatientId={selectedPatient?.id}
            />
          </div>

          {/* Middle Section - Consultation Form */}
          {/* On mobile: Second section */}
          {/* On desktop: 7 columns in the middle */}
          <div className="lg:col-span-7 bg-white rounded-xl border border-gray-200 p-4 order-2 lg:order-2">
            <ConsultationForm
              ref={consultationFormRef}
              selectedPatient={selectedPatient}
              selectedDoctor={selectedDoctor}
              onPatientPreventSwitch={handlePatientPreventSwitch}
              currentRecord={currentRecord}
            />
          </div>

          {/* Right Section - History & Chat */}
          {/* On mobile: Bottom section */}
          {/* On desktop: 3 columns on the right */}
          <div className="lg:col-span-3 bg-white rounded-xl border border-gray-200 p-4 order-3 lg:order-3">
            <HistoryAndChat
              selectedPatient={selectedPatient}
              onViewRecord={handleViewRecord}    // Add view handler
              onCopyRecord={handleCopyRecord}    // Add copy handler
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DoctorConsultation;