import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { Tab } from '@headlessui/react';
import moment from 'moment';
import { db } from './Firebase';

const PatientList = forwardRef(({
  selectedDoctor,
  targetClinicId,
  onPatientSelect,
  searchQuery,
  onSearchChange,
  selectedPatientId
}, ref) => {
  const [checkedInPatients, setCheckedInPatients] = useState([]);
  const [completedPatients, setCompletedPatients] = useState([]);
  const [localSelectedPatientId, setLocalSelectedPatientId] = useState(selectedPatientId);
  const [isLoading, setIsLoading] = useState(true);

  // Sync external selected state
  useEffect(() => {
    setLocalSelectedPatientId(selectedPatientId);
  }, [selectedPatientId]);

  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    revertSelection: (patientId) => {
      setLocalSelectedPatientId(patientId);
    }
  }));

  // Handle patient selection
  const handlePatientSelect = (patient) => {
    if (localSelectedPatientId === patient.id) return;

    if (!onPatientSelect(patient)) {
      setLocalSelectedPatientId(localSelectedPatientId);
    } else {
      setLocalSelectedPatientId(patient.id);
    }
  };

  // Real-time patient updates
  useEffect(() => {
    let unsubscribe = () => { };

    const setupRealtimeUpdates = async () => {
      if (!selectedDoctor || !targetClinicId) {
        setIsLoading(false);
        return;
      }

      const today = moment().format('YYYYMMDD');

      try {
        const q = query(
          collection(db, 'Appointment'),
          where('ownedClinicId', '==', targetClinicId),
          where('doctorId', '==', selectedDoctor.doctorId),
          where('date', '==', today)
        );

        unsubscribe = onSnapshot(q, (snapshot) => {
          const patients = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          const filterPatients = patients => {
            return patients.filter(patient => {
              const matchesSearch =
                patient.userName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                patient.userTWId?.toLowerCase().includes(searchQuery.toLowerCase());
              return matchesSearch;
            })
              .sort((a, b) => {
                const numberA = parseInt(a.number || 0, 10);
                const numberB = parseInt(b.number || 0, 10);
                return numberA - numberB;
              });
          };

          const checkedIn = filterPatients(patients.filter(p => p.status === '已報到'));
          const completed = filterPatients(patients.filter(p => p.status === '已看診'));

          setCheckedInPatients(checkedIn);
          setCompletedPatients(completed);
          setIsLoading(false);
        }, (error) => {
          console.error('Error in patient snapshot:', error);
          setIsLoading(false);
        });

      } catch (error) {
        console.error('Error setting up patient snapshot:', error);
        setIsLoading(false);
      }
    };

    setupRealtimeUpdates();

    // Cleanup subscription on unmount or when dependencies change
    return () => unsubscribe();
  }, [selectedDoctor, searchQuery, targetClinicId]);

  const PatientTable = ({ patients }) => (
    <div className="mt-2 max-h-[calc(100vh-300px)] overflow-y-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 sticky top-0">
          <tr>
            <th className="px-2 py-2 text-s font-medium text-gray-500">叫號</th>
            <th className="px-2 py-2 text-s font-medium text-gray-500">姓名</th>
            <th className="px-2 py-2 text-s font-medium text-gray-500">年齡</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {patients.map((patient, index) => (
            <tr
              key={patient.id}
              onClick={() => handlePatientSelect(patient)}
              className={`cursor-pointer ${localSelectedPatientId === patient.id
                  ? 'bg-blue-300'
                  : index % 2 === 0
                    ? 'bg-gray-100'
                    : 'bg-white'
                } hover:bg-blue-100`}
            >
              <td className="px-2 py-2 text-lg">{patient.number || '-'}</td>
              <td className="px-2 py-2 text-lg">{patient.userName}</td>
              <td className="px-2 py-2 text-lg">
                {moment().diff(moment(patient.birthday), 'years')}
              </td>
            </tr>
          ))}
          {patients.length === 0 && !isLoading && (
            <tr>
              <td colSpan="3" className="px-2 py-4 text-center text-gray-500">
                無相符的病患資料
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="space-y-4">
      <div>
        <input
          type="text"
          placeholder="搜尋姓名或身分證..."
          value={searchQuery}
          onChange={e => onSearchChange(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg"
        />
      </div>

      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2 text-sm font-medium leading-5 relative
              ${selected
                ? 'bg-white text-blue-700 shadow'
                : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'
              }`
            }
          >
            已報到
            {checkedInPatients.length > 0 && (
              <span className="absolute top-0 right-0 -mt-1 -mr-1 bg-red-500 text-white rounded-full w-5 h-5 text-xs flex items-center justify-center">
                {checkedInPatients.length}
              </span>
            )}
          </Tab>
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2 text-sm font-medium leading-5 relative
              ${selected
                ? 'bg-white text-blue-700 shadow'
                : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'
              }`
            }
          >
            已看診
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            {isLoading ? (
              <div className="flex justify-center items-center h-32">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <PatientTable patients={checkedInPatients} />
            )}
          </Tab.Panel>
          <Tab.Panel>
            {isLoading ? (
              <div className="flex justify-center items-center h-32">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <PatientTable patients={completedPatients} />
            )}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
});

export default PatientList;