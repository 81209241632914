import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format, parseISO } from 'date-fns';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../Firebase';
import { MdSearch } from 'react-icons/md';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

const MetricsDashboard = () => {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState(30);
  const [activeTab, setActiveTab] = useState('overview');
  const [clinics, setClinics] = useState([]);
  const [clinicsLoading, setClinicsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({
    key: 'createdAt',
    direction: 'desc'
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchMetrics = async () => {
      setLoading(true);
      try {
        const pageViewsQuery = query(collection(db, 'PageViews'), orderBy('timestamp', 'desc'));
        const buttonClicksQuery = query(collection(db, 'ButtonClicks'), orderBy('timestamp', 'desc'));
        const errorsQuery = query(collection(db, 'ErrorLogs'), orderBy('timestamp', 'desc'));

        const [pageViewsSnap, buttonClicksSnap, errorsSnap] = await Promise.all([
          getDocs(pageViewsQuery),
          getDocs(buttonClicksQuery),
          getDocs(errorsQuery)
        ]);

        const pageViews = pageViewsSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate?.() || new Date()
        }));

        const buttonClicks = buttonClicksSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate?.() || new Date()
        }));

        const errors = errorsSnap.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate?.() || new Date()
        }));

        setMetrics({
          pageViews,
          buttonClicks,
          errors,
          recentActivity: [...pageViews, ...buttonClicks, ...errors]
            .sort((a, b) => b.timestamp - a.timestamp)
            .slice(0, 50)
        });
      } catch (err) {
        console.error('Error fetching metrics:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
    const interval = setInterval(fetchMetrics, 5 * 60 * 1000); // Refresh every 5 minutes
    return () => clearInterval(interval);
  }, [timeRange]);

  useEffect(() => {
    const fetchClinics = async () => {
      if (activeTab === 'clinics') {
        setClinicsLoading(true);
        try {
          const clinicsRef = collection(db, 'OwnedClinic');
          const q = query(clinicsRef, orderBy('createdAt', 'desc'));
          const querySnapshot = await getDocs(q);

          const clinicsData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate?.() || new Date()
          }));

          setClinics(clinicsData);
        } catch (err) {
          console.error('Error fetching clinics:', err);
          setError(err.message);
        } finally {
          setClinicsLoading(false);
        }
      }
    };

    fetchClinics();
  }, [activeTab]);

  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const filteredAndSortedClinics = React.useMemo(() => {
    let result = [...clinics];

    if (searchTerm) {
      result = result.filter(clinic =>
        clinic.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        clinic.address?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        clinic.medicalId?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    result.sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });

    return result;
  }, [clinics, searchTerm, sortConfig]);

  const totalPages = Math.ceil(filteredAndSortedClinics.length / itemsPerPage);
  const paginatedClinics = filteredAndSortedClinics.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const SortIcon = ({ columnKey }) => {
    if (sortConfig.key !== columnKey) return null;
    return sortConfig.direction === 'asc' ? <BiChevronUp className="inline" /> : <BiChevronDown className="inline" />;
  };

  const renderClinicsTab = () => {
    if (clinicsLoading) {
      return (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      );
    }

    return (
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Joined Clinics ({filteredAndSortedClinics.length})</h2>
          <div className="flex items-center gap-4">
            <div className="relative">
              <MdSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search clinics..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="text-sm text-gray-500">
              Last updated: {format(new Date(), 'MMM d, yyyy HH:mm')}
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('name')}
                >
                  Clinic Name <SortIcon columnKey="name" />
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('address')}
                >
                  Address <SortIcon columnKey="address" />
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('medicalId')}
                >
                  Medical ID <SortIcon columnKey="medicalId" />
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('createdAt')}
                >
                  Join Date <SortIcon columnKey="createdAt" />
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {paginatedClinics.map((clinic) => (
                <tr key={clinic.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10 bg-blue-100 rounded-full flex items-center justify-center">
                        <span className="text-blue-600 font-semibold">
                          {clinic.name?.charAt(0) || '?'}
                        </span>
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {clinic.name}
                        </div>
                        <div className="text-sm text-gray-500">
                          {clinic.phone}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">{clinic.address}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">{clinic.medicalId}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {format(new Date(clinic.createdAt), 'MMM d, yyyy HH:mm')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      Active
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalPages > 1 && (
          <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> to{' '}
                  <span className="font-medium">
                    {Math.min(currentPage * itemsPerPage, filteredAndSortedClinics.length)}
                  </span>{' '}
                  of <span className="font-medium">{filteredAndSortedClinics.length}</span> results
                </p>
              </div>
              <div>
                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    Previous
                  </button>
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                    <button
                      key={page}
                      onClick={() => setCurrentPage(page)}
                      className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${page === currentPage
                        ? 'z-10 bg-blue-600 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                        : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                        }`}
                    >
                      {page}
                    </button>
                  ))}
                  <button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                  >
                    Next
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
// ... [Previous code remains the same until renderOverviewTab]

const renderOverviewTab = () => {
  return (
    <div className="space-y-8">
      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-medium text-gray-600">Total Page Views</h3>
          <p className="text-3xl font-bold text-blue-600 mt-2">
            {metrics?.pageViews?.length || 0}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-medium text-gray-600">Total Clicks</h3>
          <p className="text-3xl font-bold text-green-600 mt-2">
            {metrics?.buttonClicks?.length || 0}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-medium text-gray-600">Total Errors</h3>
          <p className="text-3xl font-bold text-red-600 mt-2">
            {metrics?.errors?.length || 0}
          </p>
        </div>
      </div>

      {/* Charts */}
      {renderPageViewsChart()}
      {renderButtonClicksChart()}
      {renderErrorsChart()}
    </div>
  );
};

const renderPageViewsChart = () => {
  const data = metrics?.pageViews?.reduce((acc, view) => {
    const date = format(view.timestamp, 'yyyy-MM-dd');
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  const chartData = Object.entries(data || {})
    .sort((a, b) => new Date(a[0]) - new Date(b[0])) // Sort by date ascending
    .map(([date, count]) => ({
      date,
      count
    }));

  return (
    <div className="bg-white p-6 rounded-lg shadow mt-6">
      <h2 className="text-xl font-semibold mb-4">Page Views Over Time</h2>
      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="count" stroke="#3b82f6" name="Page Views" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const renderButtonClicksChart = () => {
  const data = metrics?.buttonClicks?.reduce((acc, click) => {
    const date = format(new Date(click.timestamp), 'yyyy-MM-dd');
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  const chartData = Object.entries(data || {})
    .sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime())
    .map(([date, count]) => ({
      date: format(new Date(date), 'MM/dd'),
      count
    }));

  return (
    <div className="bg-white p-6 rounded-lg shadow mt-6">
      <h2 className="text-xl font-semibold mb-4">Button Clicks Over Time</h2>
      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date"
              tickFormatter={(value) => value}
            />
            <YAxis />
            <Tooltip 
              labelFormatter={(value) => `Date: ${value}`}
              formatter={(value) => [`Count: ${value}`, '']}
            />
            <Legend />
            <Line 
              type="monotone" 
              dataKey="count" 
              stroke="#10b981" 
              name="Button Clicks"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const renderErrorsChart = () => {
  const data = metrics?.errors?.reduce((acc, error) => {
    const date = format(new Date(error.timestamp), 'yyyy-MM-dd');
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  const chartData = Object.entries(data || {})
    .sort((a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime())
    .map(([date, count]) => ({
      date: format(new Date(date), 'MM/dd'),
      count
    }));

  return (
    <div className="bg-white p-6 rounded-lg shadow mt-6">
      <h2 className="text-xl font-semibold mb-4">Errors Over Time</h2>
      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date"
              tickFormatter={(value) => value}
            />
            <YAxis />
            <Tooltip 
              labelFormatter={(value) => `Date: ${value}`}
              formatter={(value) => [`Count: ${value}`, '']}
            />
            <Legend />
            <Line 
              type="monotone" 
              dataKey="count" 
              stroke="#ef4444" 
              name="Errors"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const renderDetailsTab = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Page Views Details */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Recent Page Views</h2>
        <div className="space-y-4 max-h-96 overflow-y-auto">
          {metrics?.pageViews?.slice(0, 20).map((view) => (
            <div key={view.id} className="border-b pb-2">
              <p className="text-sm font-medium">{view.page}</p>
              <p className="text-xs text-gray-500">
                {format(view.timestamp, 'MMM d, yyyy HH:mm:ss')}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Button Clicks Details */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Recent Button Clicks</h2>
        <div className="space-y-4 max-h-96 overflow-y-auto">
          {metrics?.buttonClicks?.slice(0, 20).map((click) => (
            <div key={click.id} className="border-b pb-2">
              <p className="text-sm font-medium">{click.buttonId}</p>
              <p className="text-xs text-gray-500">
                {format(click.timestamp, 'MMM d, yyyy HH:mm:ss')}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const renderActivityTab = () => {
  const getBrowserInfo = (userAgent) => {
    if (!userAgent) return 'Unknown Browser';
    if (userAgent.includes('Edg/')) return 'Edge';
    if (userAgent.includes('Chrome')) return 'Chrome';
    if (userAgent.includes('Firefox')) return 'Firefox';
    if (userAgent.includes('Safari')) return 'Safari';
    return 'Other Browser';
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <h2 className="text-xl font-semibold mb-4">Recent Activity</h2>
      <div className="space-y-4 max-h-[32rem] overflow-y-auto">
        {metrics?.recentActivity?.map((activity, index) => (
          <div key={activity.id || index} className="bg-gray-50 rounded-lg p-4 border border-gray-200">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <div className={`w-2 h-2 rounded-full ${
                  activity.type === 'pageView' 
                    ? 'bg-blue-500' 
                    : activity.type === 'buttonClick'
                    ? 'bg-green-500'
                    : 'bg-red-500'
                }`} />
                <span className="font-medium text-gray-900">
                  {activity.page || 'Unknown Page'}
                </span>
              </div>
              <span className="text-sm text-gray-500">
                {format(new Date(activity.timestamp), 'MMM d, yyyy HH:mm:ss')}
              </span>
            </div>
            
            <div className="grid grid-cols-2 gap-4 mt-2 text-sm">
              <div>
                <p className="text-gray-600">
                  <span className="font-medium">Session ID:</span>{' '}
                  <span className="font-mono">{activity.sessionId?.substring(0, 8) || 'N/A'}</span>
                </p>
                <p className="text-gray-600">
                  <span className="font-medium">Referrer:</span>{' '}
                  {activity.referrer || 'N/A'}
                </p>
              </div>
              <div>
                <p className="text-gray-600">
                  <span className="font-medium">Browser:</span>{' '}
                  {getBrowserInfo(activity.userAgent)}
                </p>
                <p className="text-gray-600">
                  <span className="font-medium">Date Key:</span>{' '}
                  {activity.dateKey || 'N/A'}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

if (loading) {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
}

if (error) {
  return (
    <div className="p-4 bg-red-100 text-red-700 rounded-lg">
      Error loading metrics: {error}
    </div>
  );
}

return (
  <div className="p-6 space-y-8">
    {/* Header Section */}
    <div className="flex justify-between items-center">
      <h1 className="text-2xl font-bold">Analytics Dashboard</h1>
      <div className="flex gap-4 items-center">
        <select 
          value={timeRange} 
          onChange={(e) => setTimeRange(Number(e.target.value))}
          className="px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value={7}>Last 7 days</option>
          <option value={30}>Last 30 days</option>
          <option value={90}>Last 90 days</option>
        </select>
        <div className="flex border rounded-lg overflow-hidden">
          {['overview', 'details', 'activity', 'clinics'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-4 py-2 transition-colors ${
                activeTab === tab 
                  ? 'bg-blue-500 text-white' 
                  : 'hover:bg-gray-100'
              }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
      </div>
    </div>

    {/* Content based on active tab */}
    {activeTab === 'overview' && renderOverviewTab()}
    {activeTab === 'details' && renderDetailsTab()}
    {activeTab === 'activity' && renderActivityTab()}
    {activeTab === 'clinics' && renderClinicsTab()}
  </div>
);
};

export default MetricsDashboard;