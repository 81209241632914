import { getDownloadURL, ref, uploadBytes } from '@firebase/storage';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';
import { BiLoaderCircle } from 'react-icons/bi';
import { FiUploadCloud } from 'react-icons/fi';
import { FaTimes, FaEye } from 'react-icons/fa';
import { storage } from './Firebase';

const Uploader = ({ images, onUpload, onDelete, path = 'images' }) => {
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // upload files
  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);
    try {
      const uploadPromises = acceptedFiles.map(async (file) => {
        const storageRef = ref(storage, `${path}/${file.name}`);
        await uploadBytes(storageRef, file);
        return getDownloadURL(storageRef);
      });

      const downloadURLs = await Promise.all(uploadPromises);
      downloadURLs.forEach(url => onUpload(url));
      toast.success('Images uploaded successfully!');
    } catch (error) {
      console.error('Error uploading files: ', error);
      toast.error('Failed to upload images. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [onUpload, path]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png']
    }
  });

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className="w-full text-center">
      {/* Image previews */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-4 mb-8">
        {images.map((image, index) => (
          <div key={index} className="relative group">
            <img
              src={image}
              alt={`preview ${index + 1}`}
              className="w-full h-32 rounded object-cover cursor-pointer"
              onClick={() => handleImageClick(image)}
            />
            <FaEye className="absolute inset-0 m-auto text-sky-500 text-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            <button
              onClick={() => onDelete(image)}
              className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1"
            >
              <FaTimes />
            </button>
          </div>
        ))}
        {loading && (
          <div className="w-full h-32 bg-dry flex-colo border-2 border-border border-dashed rounded-md">
            <BiLoaderCircle className="mx-auto text-main text-3xl animate-spin" />
            <span className="text-sm mt-2 text-text">Uploading...</span>
          </div>
        )}
      </div>
      <div
        className="px-6 pt-5 pb-6 border-2 border-dashed rounded-md cursor-pointer mb-4"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <span className="mx-auto flex justify-center">
          <FiUploadCloud className="text-3xl text-subMain" />
        </span>
        <p className="text-sm mt-2">按此拍照或上傳照片</p>
        <em className="text-xs text-gray-400">
          (只接受 *.jpeg 或 *.png )
        </em>
      </div>

      {/* Full-size image modal */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={() => setSelectedImage(null)}>
          <div className="max-w-xl max-h-[90vh] overflow-auto">
            <img src={selectedImage} alt="Full size" className="max-w-full max-h-full" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Uploader;