import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Aos from 'aos';
import Toast from './components/Notifications/Toast';
import BigLoader from './components/Notifications/BigLoader';
import Chats from './screens/Chats/Chats';
import { AuthProvider } from './components/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import { firebaseConfig } from './components/Firebase'
import { initializeApp } from 'firebase/app';
import PrivacyPolicy from './Layout/PrivacyPolicy';
import MetricsDashboard from './components/Metrics/MetricsDashboard';
import DoctorConsultation from './screens/DoctorConsultation/DoctorConsultation';

const Dashboard = React.lazy(() => import('./screens/Dashboard/Dashboard'));
const Payments = React.lazy(() => import('./screens/Payments/Payments'));
const Appointments = React.lazy(() => import('./screens/Appointments'));
const OnlineAppointment = React.lazy(() => import('./screens/OnlineAppointment'));
const Patients = React.lazy(() => import('./screens/Patients/Patients'));
const Campaings = React.lazy(() => import('./screens/Campaings'));
const Services = React.lazy(() => import('./screens/Services'));
const Invoices = React.lazy(() => import('./screens/Invoices/Invoices'));
const Settings = React.lazy(() => import('./screens/Settings/Settings'));
const TVNumberCaller = React.lazy(() => import('./screens/TVNumberCaller'));
const HandyClinicApp = React.lazy(() => import('./screens/HandyClinic'));
const AccountDeletion = React.lazy(() => import('./screens/AccountDeletion'));


const CreateInvoice = React.lazy(() =>
  import('./screens/Invoices/CreateInvoice')
);
const EditInvoice = React.lazy(() => import('./screens/Invoices/EditInvoice'));
const PreviewInvoice = React.lazy(() =>
  import('./screens/Invoices/PreviewInvoice')
);
const EditPayment = React.lazy(() => import('./screens/Payments/EditPayment'));
const PreviewPayment = React.lazy(() =>
  import('./screens/Payments/PreviewPayment')
);
const Medicine = React.lazy(() => import('./screens/Medicine'));
const PatientProfile = React.lazy(() =>
  import('./screens/Patients/PatientProfile')
);
const CreatePatient = React.lazy(() =>
  import('./screens/Patients/CreatePatient')
);
const Doctors = React.lazy(() => import('./screens/Doctors/Doctors'));
const DoctorProfile = React.lazy(() =>
  import('./screens/Doctors/DoctorProfile')
);
const Checkup = React.lazy(() => import('./screens/Checkup/Checkup'));
const EditCheckup = React.lazy(() => import('./screens/Checkup/EditCheckup'));
const Receptions = React.lazy(() => import('./screens/Receptions'));
const NewMedicalRecode = React.lazy(() =>
  import('./screens/Patients/NewMedicalRecode')
);
const NotFound = React.lazy(() => import('./screens/NotFound'));
const Login = React.lazy(() => import('./screens/Login'));
const Reviews = React.lazy(() => import('./screens/Reviews'));

initializeApp(firebaseConfig);

function App() {
  Aos.init();

  return (
    <>
      <AuthProvider>
        {/* Toaster */}
        <Toast />
        {/* Routes */}
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path="/handyClinic"
              element={
                <Suspense fallback={<BigLoader />}>
                  <HandyClinicApp />
                </Suspense>
              }
            />
            {/* invoce */}
            <Route
              path="/invoices"
              element={
                <Suspense fallback={<BigLoader />}>
                  <PrivateRoute>
                    <Invoices />
                  </PrivateRoute>
                </Suspense>
              }
            />
            <Route
              path="/invoices/create"
              element={
                <Suspense fallback={<BigLoader />}>
                  <PrivateRoute>
                    <CreateInvoice />
                  </PrivateRoute>
                </Suspense>
              }
            />
            <Route
              path="/invoices/edit/:id"
              element={
                <Suspense fallback={<BigLoader />}>
                  <PrivateRoute>
                    <EditInvoice />
                  </PrivateRoute>
                </Suspense>
              }
            />
            <Route
              path="/invoices/preview/:id"
              element={
                <Suspense fallback={<BigLoader />}>
                  <PrivateRoute>
                    <PreviewInvoice />
                  </PrivateRoute>
                </Suspense>
              }
            />
            {/* payments */}
            <Route
              path="/payments"
              element={
                <Suspense fallback={<BigLoader />}>
                  <PrivateRoute>
                    <Payments />
                  </PrivateRoute>
                </Suspense>
              }
            />
            <Route
              path="/payments/edit/:id"
              element={
                <Suspense fallback={<BigLoader />}>
                  <PrivateRoute>
                    <EditPayment />
                  </PrivateRoute>
                </Suspense>
              }
            />
            <Route
              path="/payments/preview/:id"
              element={
                <Suspense fallback={<BigLoader />}>
                  <PrivateRoute>
                    <PreviewPayment />
                  </PrivateRoute>
                </Suspense>
              }
            />
            {/* patient */}
            <Route
              path="/patients"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Patients />
                </Suspense>
              }
            />
            <Route
              path="/patients/preview/:patientId"
              element={
                <Suspense fallback={<BigLoader />}>
                  <PatientProfile />
                </Suspense>
              }
            />
            <Route
              path="/patients/create"
              element={
                <Suspense fallback={<BigLoader />}>
                  <CreatePatient />
                </Suspense>
              }
            />
            <Route
              path="/patients/visiting/:id"
              element={
                <Suspense fallback={<BigLoader />}>
                  <NewMedicalRecode />
                </Suspense>
              }
            />
            {/* doctors */}
            <Route
              path="/doctors"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Doctors />
                </Suspense>
              }
            />
            <Route
              path="/patients/checkup/:patientId"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Checkup />
                </Suspense>
              }
            />
            <Route
              path="/patients/checkup/edit/:recordId"
              element={
                <Suspense fallback={<BigLoader />}>
                  <EditCheckup />
                </Suspense>
              }
            />
            <Route
              path="/doctors/preview/:id"
              element={
                <Suspense fallback={<BigLoader />}>
                  <DoctorProfile />
                </Suspense>
              }
            />
            {/* reception */}
            <Route
              path="/receptions"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Receptions />
                </Suspense>
              }
            />
            {/* others */}
            <Route
              path="/login"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path="/accountDelete"
              element={
                <Suspense fallback={<BigLoader />}>
                  <AccountDeletion />
                </Suspense>
              }
            />
            <Route
              path="/tvNumberCaller/:clinicId"
              element={
                <Suspense fallback={<BigLoader />}>
                  <TVNumberCaller />
                </Suspense>
              }
            />
            <Route
              path="/appointments"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Appointments />
                </Suspense>
              }
            />
            <Route
              path="/onlineAppointment"
              element={
                <Suspense fallback={<BigLoader />}>
                  <OnlineAppointment />
                </Suspense>
              }
            />
            <Route
              path="/DoctorConsultation"
              element={
                <Suspense fallback={<BigLoader />}>
                  <DoctorConsultation />
                </Suspense>
              }
            />
            <Route
              path="/campaigns"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Campaings />
                </Suspense>
              }
            />
            <Route
              path="/medicine"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Medicine />
                </Suspense>
              }
            />
            <Route
              path="/services"
              element={
                <Suspense fallback={<BigLoader />}>
                  <PrivateRoute>
                    <Services />
                  </PrivateRoute>
                </Suspense>
              }
            />
            <Route
              path="/settings"
              element={
                <Suspense fallback={<BigLoader />}>
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                </Suspense>
              }
            />
            {/* reviews */}
            <Route
              path="/reviews"
              element={
                <Suspense fallback={<BigLoader />}>
                  <PrivateRoute>
                    <Reviews />
                  </PrivateRoute>
                </Suspense>
              }
            />
            {/* chats */}
            <Route
              path="/chats/:patientId?"
              element={
                <Suspense fallback={<BigLoader />}>
                  <Chats />
                </Suspense>
              }
            />

            <Route
              path="/privacy-policy"
              element={
                <Suspense fallback={<BigLoader />}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              path="*"
              element={
                <Suspense fallback={<BigLoader />}>
                  <NotFound />
                </Suspense>
              }
            />

            <Route path="/admin/metrics" element={<MetricsDashboard />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;
