import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import { useLocation } from 'react-router-dom'; // 引入 useLocation

function Index({ children, title }) {
  const location = useLocation(); // 獲取當前路由
  const isSettingsPage = location.pathname === '/DoctorConsultation'; // 判斷是否為 /settings 頁面

  return (
    <div className="bg-dry xl:h-screen flex-colo">
      <div className="grid xl:grid-cols-12 w-full 2xl:max-w-[2000px]">
        {/* 根據 isSettingsPage 決定是否隱藏側邊欄 */}
        <div className={`col-span-2 ${isSettingsPage ? 'hidden' : 'xl:block hidden'}`}>
          <Sidebar />
        </div>
        <div className={`${isSettingsPage ? 'col-span-12' : 'col-span-10'} xl:h-screen overflow-y-scroll relative`}>
          <Header title={title} />
          <div className="xs:px-3 px-2 pt-24">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Index;